* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #1a1f29;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(26, 31, 41, 0.38);
    border: 1px solid #fff;
    border-radius: 5px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

#send-file-notifications-wrapper {
    .notification-container {
        left: 50%;
        transform: translateX(-50%);
    }

    .notification {
        width: 288px;
        padding: 17px 48px 17px 16px;
        box-shadow:
            0px 2px 2px rgba(0, 0, 0, 0.04),
            0px 3px 1px rgba(0, 0, 0, 0.03),
            0px 1px 5px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        border: none;
        background: #ffe6e6;
        box-shadow:
            0px 4px 5px rgba(0, 0, 0, 0.08),
            0px 1px 10px rgba(0, 0, 0, 0.06),
            0px 2px 4px rgba(0, 0, 0, 0.09);
        margin: 12px 5px 10px;
        opacity: 1;
        padding-left: 52px;

        &::before {
            content: url("./icons/close-icon.svg");
            left: unset;
            height: 14px;
            width: 14px;
            line-height: 14px;
            font-size: 12px;
            position: absolute;
            right: 21px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 0;
        }

        &::after {
            position: absolute;
            left: 18px;
            top: 50%;
            transform: translateY(-50%);
            height: 20px;
            width: 20px;
        }

        &.notification-error {
            background: #ffe5e5;
            border-left: 4px solid #ff0000;

            &::after {
                content: url("./icons/error-icon.svg");
            }
        }

        &.notification-success {
            background: #f2ffe8;
            border-left: 4px solid #4caf50;

            &::after {
                content: url("./icons/success-icon.svg");
            }
        }

        .notification-message {
            line-height: 14px;
            font-family: "Roboto";
            font-size: 12px;
            color: #1a1f29;

            .title {
                display: none;
            }
        }
    }
}
